// const config = {
//   API_URL: "https://atri-document-stg-api.bitcotapps.com/",
// };
// export default config;


let url;
if (process.env.REACT_APP_ENV === "dev") {
  url = `https://atriresearch-api-stage.unincorporated.pro/`;
}
if (process.env.REACT_APP_ENV === "staging") {
  console.log("staging if");
  url = `https://atriresearch-api-stage.unincorporated.pro/`;
}
if (process.env.REACT_APP_ENV === "production") {
  console.log("production if");
  url = `https://atriresearch-api.unincorporated.pro/`;
}

const config = {
  API_URL : url
};
export default config;

